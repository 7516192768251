import React from 'react';

import Logo from '../../Assets/Logo.svg';
import Facebook from '../../Assets/Socials/Facebook.png';
import Instagram from '../../Assets/Socials/Instagram.png';
import LinkedIn from '../../Assets/Socials/LinkedIn.png';
import Twitter from '../../Assets/Socials/Twitter.png';
import { Link } from 'react-router-dom';
// import YouTube from '../../Assets/Socials/YouTube.png';

function Footer() {
    return (
        <div className='px-[50px] max-[1280px]:px-[35px] max-[426px]:px-0 pb-5 bg-[#FFFFFF] max-[426px]:flex max-[426px]:flex-col max-[426px]:gap-8 shadow-[0_0_8px_0_#00000045]'>
            <div className="px-[100px] max-[1200px]:py-12 py-16 max-[426px]:p-8 flex flex-col items-center max-[426px]:items-start gap-8 max-[426px]:gap-6">
                <div className="flex max-[426px]:flex-col gap-8 max-[426px]:gap-6">
                    <div className="flex flex-col gap-2">
                        <h1 className='text-title1 text-[#000000] max-[426px]:text-label1'>Company</h1>

                        <div className="flex flex-col gap-1 text-[#666666] text-body1 max-[426px]:text-body3">
                            {/* <Link to='/about-hyprcom'>About Us</Link> */}
                            {/* <p>FAQ's</p> */}
                            {/* <p>Blogs</p> */}
                        </div>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h1 className='text-title1 text-[#000000] max-[426px]:text-label1'>Consumer Policy</h1>

                        <div className="flex flex-col gap-1 text-[#666666] text-body1 max-[426px]:text-body3">
                            <Link to='/terms-and-conditions'>Terms & Conditions</Link>
                            <Link to='/privacy-policy'>Privacy Policy</Link>
                            {/* <p>Return/ Exchange Policy</p> */}
                        </div>
                    </div>
                </div>

                <div className="flex items-center gap-8 max-[426px]:gap-6">
                    <a href="https://www.linkedin.com/company/hyprcom" target='_blank' rel='noopener noreferrer'>
                        <div className="size-8 max-[426px]:size-6">
                            <img className='size-full' src={LinkedIn} alt="LinkedIn" />
                        </div>
                    </a>

                    <a href="https://www.instagram.com/hyprcom" target='_blank' rel='noopener noreferrer'>
                        <div className="size-8 max-[426px]:size-6">
                            <img className='size-full' src={Instagram} alt="Instagram" />
                        </div>
                    </a>

                    <a href="https://www.facebook.com/hyprcom" target='_blank' rel='noopener noreferrer'>
                        <div className="size-8 max-[426px]:size-6">
                            <img className='size-full' src={Facebook} alt="Facebook" />
                        </div>
                    </a>

                    {/* <a href="" target='_blank' rel='noopener noreferrer'>
                        <div className="size-8 max-[426px]:size-6">
                            <img className='size-full' src={YouTube} alt="YouTube" />
                        </div>
                    </a> */}

                    <a href="https://x.com/Hyprcom_in" target='_blank' rel='noopener noreferrer'>
                        <div className="size-8 max-[426px]:size-6">
                            <img className='size-full' src={Twitter} alt="Twitter" />
                        </div>
                    </a>
                </div>
            </div>

            <div className="flex flex-col items-center gap-8 max-[426px]:gap-4">
                <div className="h-12 max-[426px]:h-8">
                    <img className='h-full w-auto select-none' src={Logo} alt="Hyprcom Logo" />
                </div>

                <p className='text-[#666666] text-label1 max-[426px]:text-body2 select-none'>© 2024 · Hyprcom</p>
            </div>
        </div>
    );
}

export default Footer;

import React from 'react'
import CategoryBox from './CategoryBox';

function Categories({ categories }) {
    return (
        <div className="flex flex-col gap-3">
            <h1 className='mx-4 text-title2 text-[#666666]'>{categories?.name}</h1>

            <div className="px-4 w-full flex items-center gap-2 overflow-x-auto scrollbar-hide">
                {categories?.categoryLinkList.map(category => {
                    return (
                        <CategoryBox 
                            key={category.id} 
                            category={category} 
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default Categories;
